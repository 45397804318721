<template>
    <div class="container bg__white tables__wrapper" v-if="year">
        <StickyHeader v-if="topicCode"
                      :topicCode="topicCode"
                      page="Table">

            <NestedDropdown :onchange="changeLocation" :selected="selectedLocation" label="Location" :showAllLocationItem="true" />


            <Dropdown v-if="strats.length && allLocations" class="d-print-none" label="Column" :selected="strat" :options="strats" :onchange="changeStrat" />


            <Dropdown label="Year" :selected="selectedYear" :options="years" :onchange="changeYear" />





        </StickyHeader>



        <p class="d-none d-print-inline">Year: {{ selectedYear }}</p>
        <p :class="['d-none', shouldPrint('stateLocal', 'd-print-block')]">Column Variable: {{ strat }}</p>
        <div v-if="!fetchingTableData" class="row tables__wrapper--content">
            <div class="col-12" id="table-wrapper">
                <!-- National -->
                <div class="label-and-links">
                    <p class="header col-8"
                       :style="{ color }">{{ this.getLocation}} Survey Results</p>
                    <LinksYOLChartTable type="graph"
                                        :hideGraphAndYOLLink="!year.National"
                                        :queryParams="queryParams" />
                </div>
                <div v-if="!stateOrNationalDataset" class="alert alert-danger d-print-none" role="alert">No Data Available</div>

                <AllLocationsContainer v-if="allLocations"
                                       :nationalTableData="stateOrNationalDataset"
                                       :stickyHeaderTableData="stickyHeaderTableData"
                                       id="national-alllocations"
                                       tabindex="-1"
                                       nationalTextLabel="United States"
                                       :allLocations="allLocations"
                                       :nationalHeaders="nationalHeaders"
                                       :strat="strat"
                                       :shouldPrint="shouldPrint('allLocations')"
                                       :stateTableData="year.State"
                                       :stateHeaders="stateHeaders"
                                       :stateMedianRange="medianRange.State"
                                       stateTextLabel="State Survey"
                                       :localTableData="year.Local"
                                       :localHeaders="stateHeaders"
                                       :localMedianRange="medianRange.Local"
                                       localTextLabel="Local Survey">
                    >
                </AllLocationsContainer>

                <Table v-if="!allLocations"
                       v-for="(value, name) in stateOrNationalDataset"
                       id="national"
                       tabindex="-1"
                       :label="name"
                       :tableData="value"
                       :headers="nationalHeaders"
                       :strat="null"
                       :key="name"
                       :class="shouldPrint('location')"></Table>

                <Observer @onScreen="addStrat = true"
                          @offScreen="addStrat = false"
                          :elementSelector="toObserve()" />
                <Footnotes v-if="year.National && !allLocations"
                           :footnotes="footnotes.National"
                           :missingData="hasEmptyValues(year.National)"
                           :filterCB="tableFilter"
                           :class="shouldPrint('location')" />
                <div v-if="year.National && !allLocations" class="tables__buttons d-print-none">
                    <div>
                        <button @click="exportToPDF('location')"
                                :class="[
                'btn mr-2 d-print-none',
                `yrbs-cat__color--${topicCode}`
              ]">
                            Print/Save to PDF
                        </button>
                        <button :class="[
                'btn mr-2 d-print-none',
                `yrbs-cat__color--${topicCode}`
              ]"
                                @click="locationExport">
                            Export to CSV
                        </button>
                    </div>
                </div>

                <hr v-if="allLocations && !allLocations" />
                <div v-if="allLocations">
                    <!--
                    <Footnotes v-if="strats.length && !allLocations"
                               :footnotes="footnotes.Local"
                               :strat="strat"
                               :missingData="hasEmptyValues(year.Local, strat)"
                               :filterCB="tableFilter"
                               :class="shouldPrint('allLocations')" />
                        -->
                    <Footnotes v-if="year.National"
                               :footnotes="footnotes.National"
                               :missingData="hasEmptyValues(year.National)"
                               :filterCB="tableFilter"
                               :class="shouldPrint('allLocations')" />


                    <button v-if="strats.length"
                            @click="exportToPDF('allLocations')"
                            :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]">
                        Print/Save to PDF
                    </button>
                    <button v-if="strats.length"
                            :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]"
                            @click="allLocationsExport">
                        Export to CSV
                    </button>
                </div>
            </div>
            <p class="col pt-3">
                <b>Recommended Citation:</b> Centers for Disease Control and Prevention (CDC). 1991-2021 High School Youth Risk Behavior Survey Data.
                Available at
                <a href="http://yrbs-explorer.services.cdc.gov/">http://yrbs-explorer.services.cdc.gov/</a>. Accessed on [date].
            </p>
        </div>
        <div v-else class="tables__loader--container">
            <Loader />
        </div>
        <ScrollToTop scrollTo=".tables__wrapper" class="d-print-none" />
    </div>
    <div v-else class="tables__loader--container">
        <Loader v-if="!errorMessage" />
        <!--ERROR MESSAGE-->
        <div v-else class="alert alert-danger" role="alert">{{ errorMessage }}</div>
    </div>

</template>

<script>
    import Loader from '../modules/Loader.vue';
    import StickyHeader from '../modules/StickyHeader.vue';
    import LinksYOLChartTable from '../modules/LinksYOLChartTable';
    import Observer from '../modules/Observer';
    import Table from './modules/Table.vue';
    import AllLocationsContainer from './modules/AllLocationsContainer.vue';
    import { getHeaders, getStateLocalHeaders, getHeadersAllLocations, hasEmptyValues, formatYearData } from './helpers';
    import * as analytics from './analyticsHelpers';
    import { insertQueryParam, isInternetExplorer } from '../../utils/helpers';
    import VueScrollTo from 'vue-scrollto';
    import Dropdown from '../modules/Dropdown.vue';
    import tileDictionary from '../../utils/tileDictionary';
    import ScrollToTop from '../modules/ScrollToTop.vue';
    import Footnotes, { tableFilter } from '../modules/Footnotes';
    import { csvFootnotes, saveCSV } from '../../utils/csvExportHelpers';
    import NestedDropdown from '../modules/NestedDropdown/index.js';
    import locationDictionary from '../../utils/locationDictionary';
    import * as api from '../../utils/api';
    export default {
        components: {
            Table,
            Loader,
            StickyHeader,
            Dropdown,
            ScrollToTop,
            Footnotes,
            Observer,
            LinksYOLChartTable,
            NestedDropdown,
            AllLocationsContainer
        },
        data() {
            return {
                selectedYear: '',
                strat: 'Total',
                strats: [],
                optionYears: [],
                addStrat: true,
                toPrint: '',
                fetchingTableData: false,
                selectedLocation: this.$route.query.location || 'XX',
            };
        },
        beforeUpdate() {
            if (this.selectedYear) {
                Object.assign(this.$route.query, { year: this.selectedYear });
                insertQueryParam('year', this.selectedYear);
            } else if (!this.selectedYear && this.$route.query.year) {
                // this fixes the edge case where user navigates away from the page
                // and then comes back to it using the same tile link.
                this.selectedYear === this.$route.query.year;
            }
            if (this.selectedLocation) {
                Object.assign(this.$route.query, { location: this.selectedLocation });
                insertQueryParam('location', this.selectedLocation);
            }
        },
        created() {
            window.scrollTo(0, 0);
        },
        mounted() {
            const { questionCode, year } = this.$route.query;
            this.getTableData(questionCode, year);
            const logo = document.querySelector('.cdc-logo a');
            if (logo) logo.focus();
        },
        computed: {

            allLocations() {
                if (this.selectedLocation === 'All') return true;
                return false;
            },
            stateOrNationalDataset() {
                const nationalItem = this.selectedLocation == 'XX' ? this.year.National : null;
                const allLocationsItem = this.allLocations ? this.year.National : null;
                const stateItem = this.year.State ? this.year.State[this.getLocation] : null;
                const localItem = this.year.Local ? this.year.Local[this.getLocation] : null;

                return nationalItem || allLocationsItem || stateItem || localItem;
            },
            exportType() {
                if (this.selectedLocation == 'XX') {
                    return 'National';
                }
                else if (this.allLocations) {
                    return 'All_Locations';
                }
                else if (this.year.State && this.year.State[this.getLocation]) {
                    return 'State';
                }
                else if (this.year.Local && this.year.Local[this.getLocation]) {
                    return 'Local';
                }
                return 'unknown';

            },
            stickyHeaderTableData() {
                const value = this.stateOrNationalDataset[0]
                return value;
            },
            isIE() {
                return isInternetExplorer();
            },
            graphLink() {
                const { questionCode, topicCode, year } = this.$route.query;
                return `/#/graphs?questionCode=${questionCode}&topicCode=${topicCode}&year=${year}`;
            },
            footnotes() {
                return this.$store.state.tables.footnotes;
            },
            queryParams() {
                // ensures reactivity for the LinksYOLChartTable component.
                // the below overrides the $route.query.
                return { year: this.selectedYear };
            },
            year() {
                return this.$store.state.tables.years[this.selectedYear];
            },
            medianRange() {
                return this.$store.state.tables.medianRange[this.selectedYear] || {};
            },
            years() {
                return this.selectedLocation === 'XX' || this.selectedLocation === 'All' ? this.$store.state.ui.QuestionYears : this.$store.state.ui.StateLocalYears;
            },
            topicCode() {
                return this.$route.query.topicCode;
            },
            nationalHeaders() {
                const national = this.$store.state.tables.years[this.selectedYear].National

                if (this.strat === "Total") {
                    return getHeaders(national[this.strat].Total);
                } else {
                    return getHeadersAllLocations(national[this.strat]);
                }
            },
            stateHeaders() {
                const states = this.$store.state.tables.years[this.selectedYear].State;
                if (states) {
                    const firstState = Object.keys(states)[0];
                    if (this.strat === "Total") {
                        return getHeaders(states[firstState][this.strat].Total);
                    } else {
                        return getStateLocalHeaders(states[firstState][this.strat]);
                    }
                }
                return [];
            },
            color() {
                const { topicCode } = this.$route.query;
                return topicCode ? tileDictionary[topicCode].color : '';
            },
            error() {
                return this.$store.state.ui.errors.table;
            },

            getLocation() {
                return this.allLocations ? 'All Locations' : locationDictionary.byCodeFlat()[this.selectedLocation];
            },
        },
        methods: {
            byCodeFlat() {
                const bC = this.year;
                return {
                    ...bC.National,
                    ...bC.State,
                    ...bC.Local
                };
            },
            getTableData(questionCode, year, location) {
                const { $store } = this;
                const selectedLocation = location || this.selectedLocation || this.$route.query;
                this.fetchingTableData = true;
                const _this = this;
                const Keys = Object.keys;
                $store
                    .dispatch('tables/getTableData', { questionId: questionCode, year, locationId: selectedLocation })
                    .then((yr) => {
                        const { state } = $store;
                        const selected = yr;
                        _this.selectedYear = selected;
                        const states = state.tables.years[selected].National;
                        if (states) {
                            const strats = Keys(states).map(function (item) { return item == "Total" ? 'Sex' : item; });;
                            _this.strats = strats;
                        }
                        _this.strat = 'Total';
                        _this.selectedLocation = selectedLocation;
                        _this.fetchingTableData = false;
                    });
            },
            toObserve() {
                return '#table-wrapper div:nth-child(3)';
            },
            shouldPrint(printArea, className = '') {
                return printArea !== this.toPrint ? 'd-print-none' : className;
            },
            exportToPDF(printArea) {
                if (printArea === 'location') analytics.USPDFExport(this.$route.query);
                if (printArea === 'allLocations')
                    analytics.locationsPDFExport({
                        ...this.$route.query,
                        strat: this.strat,
                    });
                this.toPrint = printArea;
                this.$nextTick(() => {
                    window.print();
                });
            },
            scrollTo(loc, runAnalytics = true, tab = false) {
                // offset must be the height of the sticky header so that the element
                // scrolled to doesn't get overlapped unless it's IE because we can't
                // do sticky in IE
                const offset = this.isIE
                    ? 0
                    : -document.querySelector('.sticky-header__wrapper').offsetHeight;
                if (runAnalytics) {
                    analytics.selectOnThisPage({
                        scrollToLocation: loc,
                        ...this.$route.query,
                        strat: this.strat,
                    });
                }
                if (tab) {
                    document.getElementById(loc).focus();
                } else {
                    VueScrollTo.scrollTo(`#${loc}`, 500, { offset });
                }
            },
            changeStrat(newStrat) {

                if (this.isIE) {
                    this.scrollTo('state');
                }
                analytics.selectColumnVariable({
                    ...this.$route.query,
                    strat: newStrat,
                });
                newStrat = newStrat === "Sex" ? newStrat.replace('Sex', 'Total') : newStrat;
                this.strat = newStrat;
            },
            changeYear(year) {
                analytics.selectYear({ ...this.$route.query, strat: this.strat });
                this.getTableData(this.$route.query.questionCode, year);
            },
            changeLocation(location) {
                const { questionCode, year } = this.$route.query;
                analytics.selectLocation({ locationCode: location, questionCode: questionCode, year: year });

                this.getTableData(questionCode, year, location);
            },
            locationExport() {
                const notes = this.footnotes.National;
                analytics.USCSVExport(this.$route.query);
                this.csvExport('NationalStateLocal', notes);
            },

            csvExport(type, footnotes) {
                const topic = tileDictionary[this.topicCode].text;
                const question = this.$store.state.ui.Question;
                let $data = this.$store.getters[`tables/flattened${type}`](
                    this.selectedYear,
                    topic,
                    question,
                    this.getLocation,
                    this.strat,

                );
                const [Footnote1, Footnote2, Footnote3, Footnote4] = csvFootnotes(
                    footnotes,
                    /data were not available/,
                    true
                );
                const { questionCode, topicCode } = this.$route.query;
                $data = $data.map((d) => {
                    return {
                        ...d,
                        Footnote1,
                        Footnote2: d.DataValue ? '' : Footnote2,
                        Footnote3: type === 'StateLocal' ? '' : Footnote3, // hispanic footnote doesn't show for state local
                        Footnote4,
                        TopicID: topicCode,
                        QuestionID: questionCode,
                    };
                });
                saveCSV($data, `YRBS_${this.exportType}_Table.csv`);
            },
            allLocationsExport() {
                const footnotes = this.footnotes.National;
                let type = 'NationalAllLocations'
                const location = 'United States'
                analytics.locationsCSVExport({ ...this.$route.query, strat: this.strat });



                const topic = tileDictionary[this.topicCode].text;
                const question = this.$store.state.ui.Question;
                let $data = this.$store.getters[`tables/flattened${type}`](
                    this.selectedYear,
                    topic,
                    question,
                    location,
                    this.strat,
                    this.allLocations
                );
                const [Footnote1, Footnote2, Footnote3, Footnote4] = csvFootnotes(
                    footnotes,
                    /data were not available/,
                    true
                );
                const { questionCode, topicCode } = this.$route.query;
                $data = $data.map((d) => {
                    return {
                        ...d,
                        Footnote1,
                        Footnote2: d.DataValue ? '' : Footnote2,
                        Footnote3: type === 'StateLocal' ? '' : Footnote3, // hispanic footnote doesn't show for state local
                        Footnote4,
                        TopicID: topicCode,
                        QuestionID: questionCode,
                    };
                });

                type = 'StateLocalAllLocations'
                let $data2 = this.$store.getters[`tables/flattened${type}`](
                    this.selectedYear,
                    topic,
                    question,
                    this.strat,
                    this.allLocations
                );

                $data2 = $data2.map((d) => {
                    return {
                        ...d,
                        Footnote1,
                        Footnote2: d.DataValue ? '' : Footnote2,
                        Footnote3: type === 'StateLocal' ? '' : Footnote3, // hispanic footnote doesn't show for state local
                        Footnote4,
                        TopicID: topicCode,
                        QuestionID: questionCode,
                    };
                });

                const newDataSet = [...$data, ...$data2];

                saveCSV(newDataSet, `YRBS_${this.exportType}_Table.csv`);
            },
            hasEmptyValues,
            tableFilter,
            getYears() {
                const _this = this;
                const { questionCode, location } = this.$route.query;

                const args = { questionId: questionCode, locationId: location };
                const chartPromise = api.getYearsByLocationAndQuestion(args);
                return Promise.all([chartPromise])
                    .then(([yearData]) => {
                        this.optionYears = formatYearData({ yearData });
                    })
                    .catch((error) => {
                        _this.errorMessage = error.message || error.status;

                        console.error(error.message || error.status);
                    });
            }
        },

    };
</script>

<style lang="scss">
    @import '../../scss/variables';
    @import '../../scss/breakpoints';

    .tables {
        &__wrapper {
            position: relative;

            &--content {
                margin-top: 20px;

                .label-and-links {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    align-items: flex-end;

                    & > p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__loader {
            &--container {
                position: relative;
                height: 500px;
            }
        }

        &__sticky--years {
            position: sticky;
            top: 118px;
            margin-bottom: 20px;
        }
    }

    .column-variable-dropdown-wrapper {
        width: 100%;
        text-align: right;
        text-align: -webkit-right;
        margin-bottom: 2px;
        background-color: white;

        div {
            padding: 4px;
        }

        .column-variable-dropdown {
            border-radius: 10px;
            text-align: center;
            display: block;
            width: 80%;
        }

        .dropdown__wrapper {
            width: auto;
            display: block;
        }
    }

    div.sticky {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 210px;
    }

    /*BEGIN BEGIN SHARED TABLE CSS Local, State, National, All*/
    $lborder: 1px solid black;
    $bborder: 4px solid black;

    .table {
        // .table is an override class of bootstrap
        border-collapse: separate;
        border-spacing: 0;
        font-size: 14px;

        caption {
            display: none;
        }

        td {
            white-space: nowrap;
        }

        th[scope='colgroup'] {
            border-radius: 25px 25px 0 0;
            border-top: none;
            border-left: 1px solid $graylightest;
            border-right: 1px solid $graylightest;
            color: white;
        }

        thead, th, td {
            border-top: $lborder;
            border-left: $lborder;
            font-weight: normal;
            padding: 0.25rem;
        }

        thead {
            th {
                border-bottom: none;
            }

            tr:last-child th:last-child {
                border-right: $lborder;
            }
        }

        td:last-child {
            border-right: $lborder;
        }

        tbody tr.tableData:last-child {
            td,
            th {
                border-bottom: $lborder;
            }

            td:nth-child(2), td:nth-child(3) {
                border-bottom: $bborder;
            }
        }

        tr.medianRange:last-child {
            td,
            th {
                border-bottom: $lborder;
            }

            td:nth-child(2) {
                border-bottom: $bborder;
            }
        }


        td,
        th[scope='col'],
        th[scope='colgroup'] {
            text-align: center;
        }

        th[scope='col']:first-of-type {
            text-align: left;
        }

        .table-type--none {
            border: none;
        }

        &.totaled {
            thead {
                tr:first-child {
                    th:first-of-type {
                        border-left: $bborder;
                        border-top: $bborder;
                        border-right: $bborder;
                        border-radius: 25px 25px 0 0;
                    }
                }

                tr:nth-child(2) {

                    th:nth-child(2) {
                        border-left: $bborder;
                    }

                    th:nth-child(3) {
                        border-right: $bborder;
                    }
                }

                tr:nth-child(2) {
                    th:nth-child(1) {
                    }

                    th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6), th:nth-child(7) {
                        text-align: center;
                    }
                }
            }

            tbody {
                tr {
                    &.medianRange {
                        th {
                            font-weight: bold;
                            text-align: right;
                        }

                        td:nth-child(2) {
                            border-left: $bborder;
                            border-right: $bborder;
                        }

                        td:nth-child(3) {
                            border-right: none;
                        }
                    }

                    td:nth-child(2) {
                        border-left: $bborder;
                        font-weight: bold;
                    }

                    td:nth-child(3) {
                        border-right: $bborder;
                    }
                }
            }
        }
    }

    .tool-tip {
        color: $link;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &--item {
            position: absolute;
            left: 80%;
            top: 0;
            background: white;
        }
    }

    #national-Total thead {
        tr:nth-child(2) {
            th:nth-child(1) {
                border-left: 0px;
                border-top: 0px;
            }
        }
    }

    #national-Total tbody tr:first-child {
        th, td {
            font-size: 22px;
        }

        th:nth-child(1), td:nth-child(2), td:nth-child(4), td:nth-child(6) {
            font-weight: bold;
        }
    }

    thead tr:nth-child(2) {
        th:first-child {
            font-weight: bold;
            font-weight: 700;
        }
    }

    /*END END END SHARED TABLE CSS Local, State, National, All*/

    @media print {

        .table {
            thead, th, td {
                font-size: 10px;
                top: 0px;
            }

            th[scope=col]:first-of-type {
                font-size: 12px;
            }

            tbody tr:first-child th:nth-child(1) {
                font-size: 10px;
            }

            tbody, th, td {
                font-size: 10px !important;
            }
        }
    }
    // .sticky-dropdown-container {
    //     display: table;
    //
    //     @media (max-width: $sm) {
    //         display: inline-grid;
    //     }
    //
    //     @media (min-width: $sm)  and (max-width: $md) {
    //         display: block;
    //     }
    //
    //     @media (min-width: $md) and (max-width: $lg) {
    //         display: block;
    //     }
    //     .sticky-dropdown-item {
    //         display: table-cell;
    //     }
    // }

</style>
