<template>
    <div class="barchart__wrapper"></div>
</template>

<script>
    import { createBarChart } from './d3.methods';
    import { select } from "d3-selection";
    export default {
        props: ['chartData', 'color', 'location', 'selectedYear', 'stratTypesList', 'showCIOnBarGraph', 'barChartId'],
        data() {
            return {
                margin: {
                    top: 0,
                    right: 30,
                    bottom: 20,
                    left: 300
                },
                isMobile: false,
                height: 800,
                width: this.calcWidth(),
                dataBySortOrder: null
            };
        },
        updated() {            
            select("#" + this.barChartId).remove();
            this.createBarChart(this);
        },
        mounted() {
            this.createBarChart(this);
        },
        computed: {
            title() {
                const question = this.$store.state.ui.Question.replace(
                    '*',
                    ''
                ).toLowerCase();
                const location = this.location;
                const year = this.selectedYear;
                const strats = this.stratTypesList;
                return `Bar chart showing responses to high school students who ${question} in ${location} in ${year} by ${strats}.`;
            },
            description() {
                let desc = 'Bar chart shows';
                const lastIndex = this.chartData.length - 1;
                function fmt(val, strat) {
                    const value = val || 'nothing';
                    return `${value} for ${strat}`;
                }
                this.chartData.forEach(({ MainValue, Strat, Year }, i) => {
                    if (Year !== this.selectedYear) return null;
                    i === lastIndex
                        ? (desc += ` and ${fmt(MainValue, Strat)}.`)
                        : (desc += ` ${fmt(MainValue, Strat)},`);
                });
                return desc + ' See the table view for additional details.';
            }
        },
        methods: {
            calcWidth() {
                const availableWidth = window.outerWidth - 30;
                if (availableWidth > 1140) return 1140;
                return availableWidth;
            },
            getFormattedChartData() {
                const { selectedYear, chartData } = this;
                let dataBySortOrder = [];
                for (let i = 0; i < chartData.length; i++) {
                    const currentData = chartData[i];
                    if (selectedYear == currentData.Year) {
                        const { StratTypeOrder, StratOrder } = currentData;
                        if (!dataBySortOrder[StratTypeOrder]) {
                            dataBySortOrder[StratTypeOrder] = [];
                        }
                        dataBySortOrder[StratTypeOrder][StratOrder] = currentData;
                    }
                }
                let totalBars = 0;
                dataBySortOrder = dataBySortOrder
                    .filter(strat => strat !== undefined)
                    .map(stratType =>
                        // some of the StratType orders start with 0 some start with 2
                        // this will filter out the empty values that are placed in the array
                        stratType.filter(strat => {
                            if (strat !== undefined) {
                                totalBars++;
                                return true;
                            }
                            return false;
                        })
                    );
                dataBySortOrder['totalBars'] = totalBars;
                return dataBySortOrder;
            },
            createBarChart() {
               if (this.$store.state.ui.isMobile) {
                   this.width = window.outerWidth - 20;
                   this.margin.left = 200;
                   this.isMobile = true;
               }
                this.dataBySortOrder = this.getFormattedChartData();
                this.height =this.dataBySortOrder.totalBars * 35 + this.dataBySortOrder.length * 35;
                createBarChart(this);
            }
        }
    };
</script>

<style lang="scss">
    .barchart {
        &__wrapper {
            justify-content: center;
        }
    }    
</style>
