import * as api from '../../utils/api';
export const state = {
    years: {},
    medianRange: {},
    footnotes: {},
    lastFetchedQuestionId: '',
    lastFetchedLocationId: '',
};

export const mutations = {
    setYears(state, [years, year]) {
        state.years[year] = years;
    },
    setMedianRange(state, [medianRange]) {
        state.medianRange = medianRange;
    },
    setFootNotes(state, footnotes) {
        state.footnotes = footnotes;
    },
    resetAll(state) {
        state.years = {};
        state.medianRange = {};
        state.footnotes = {};
    },
    setQuestionId(state, [questionId]) {
        state.lastFetchedQuestionId = questionId;
    },
    setLocationId(state, [locationId]) {
        state.lastFetchedLocationId = locationId;
    }
};

function formatTableDataByYear(resp) {
    const yearsObject = {};
    resp.forEach(data => {
        let {
            LocationType,
            LocationTxt,
            StratType,
            Strat,
            MainValue,
            HighCI,
            LowCI,
            LocationTypeOrder,
            StratOrder,
            SubStrat
        } = data;

        //things to note:
        //    When National, State, or local is selected as location, the table will be the same across all there.
        //    When the 'All Locations' is selected, the total for nation will be displayed and other stratifications will be selected. Additionally, State and local table will
        //    be shown with stratifications shown across columns. Due to this requirement, there are some things that need to be noted.
        //    'All Locations' : StratOrder needs to be what is coming from the database. That value is set to display strat columns
        //    'National / State / Local' : Strat order needs to be based off of Total/Female/Male 1,2,3)
        //an extra column was added to the db output for state and local. The strat category is in the locationtxt for national. For state and local,
        //locationTxt is the state or locality name

        if (LocationTxt === "") LocationTxt = "Total";

        if ((LocationType === 'State' || LocationType === 'Local') && StratType == 'Sex') Strat = SubStrat;

        if (!yearsObject[LocationType]) yearsObject[LocationType] = {};
        if (!yearsObject[LocationType][LocationTxt])
            yearsObject[LocationType][LocationTxt] = {};
        if (!yearsObject[LocationType][LocationTxt][StratType]) {
            yearsObject[LocationType][LocationTxt][StratType] = {};
        }

        //right hand of if added for new subStrat column
        if (!yearsObject[LocationType][LocationTxt][StratType][Strat] || !yearsObject[LocationType][LocationTxt][StratType][Strat][SubStrat]) {

            //need to do some object cleanup for new subStrat column
            if ((LocationType === 'State' || LocationType === 'Local') && StratType === "Sex") {
                if (!yearsObject[LocationType][LocationTxt]["Total"])
                    yearsObject[LocationType][LocationTxt]["Total"] = {};
                if (!yearsObject[LocationType][LocationTxt]["Total"]["Total"])
                    yearsObject[LocationType][LocationTxt]["Total"]["Total"] = {};
            }

            if ((LocationType === 'State' || LocationType === 'Local') && StratType !== "Sex") {
                if (!yearsObject[LocationType][LocationTxt][StratType][Strat])
                    yearsObject[LocationType][LocationTxt][StratType][Strat] = {};
                if (!yearsObject[LocationType][LocationTxt][StratType][Strat][SubStrat])
                    yearsObject[LocationType][LocationTxt][StratType][Strat][SubStrat] = {};
            }


            if ((LocationType === 'State' || LocationType === 'Local') && StratType === "Sex") {
                yearsObject[LocationType][LocationTxt]["Total"]["Total"][Strat] = {
                    MainValue,
                    HighCI,
                    LowCI,
                    LocationTypeOrder,
                    StratOrder
                };
            }
            else if ((LocationType === 'State' || LocationType === 'Local') && StratType !== "Sex") {
                yearsObject[LocationType][LocationTxt][StratType][Strat][SubStrat] = {
                    MainValue,
                    HighCI,
                    LowCI,
                    LocationTypeOrder,
                    StratOrder: getOrder(SubStrat),
                    StratOrder2: StratOrder,
                };
            }
            else {
                yearsObject[LocationType][LocationTxt][StratType][Strat] = {
                    MainValue,
                    HighCI,
                    LowCI,
                    LocationTypeOrder,
                    StratOrder
                };
            }

        }
    });

    for (const key in yearsObject.State) {
        delete yearsObject.State[key].Sex;
    }
    for (const key in yearsObject.Local) {
        delete yearsObject.Local[key].Sex;
    }

    return yearsObject;
}
function getOrder(SubStrat) {
    if (SubStrat === 'Total') return 1;
    if (SubStrat === 'Female') return 2;
    if (SubStrat === 'Male') return 3;
}
function formatMedianRangeByYear(medianRangeData) {
    const medianRangeObj = {};
    medianRangeData.forEach(data => {
        const {
            LocationType,
            MinValue,
            MaxValue,
            MedianValue,
            StratificationType,
            Stratification,
            StratOrder,
            Yr
        } = data;
        if (!medianRangeObj[Yr]) medianRangeObj[Yr] = {};
        if (!medianRangeObj[Yr][LocationType])
            medianRangeObj[Yr][LocationType] = {};
        if (!medianRangeObj[Yr][LocationType][StratificationType])
            medianRangeObj[Yr][LocationType][StratificationType] = {};
        if (!medianRangeObj[Yr][LocationType][StratificationType][Stratification])
            medianRangeObj[Yr][LocationType][StratificationType][Stratification] = {
                MinValue,
                MaxValue,
                MedianValue,
                StratOrder
            };
    });
    return medianRangeObj;
}

function formatFootNotes(footnotes) {
    const notes = {
        National: [],
        States: [],
        Local: []
    };
    footnotes.forEach(ft => {
        notes[ft.LocationType][ft.DisplayOrder - 1] = {
            symbol: ft.FootnoteSymbol,
            text: ft.FootnoteText,
            stratType: ft.StratificationType
        };
    });
    return notes;
}
export function formatYearData({ yearData }) {

    let years = [];
    yearData.forEach((item, index) => {
        years[index] = item;
    });

    return years.join(', ');
}
function getTableDataByYear(commit, questionId, year, locationId) {
    return new Promise(resolve => {
        api
            .getTableData({ questionId, year, locationId: locationId })
            .then(resp => {
                if (resp.length) {
                    commit('setYears', [formatTableDataByYear(resp), year]);
                }
                resolve();
            })
            .catch(error => {
                commit(
                    'ui/setError',
                    { type: 'table', payload: error.message },
                    { root: true }
                );
            });
    });
}

function getFootnotes(commit, questionId) {
    const footNotes = api.getTableFootNotes({ questionId });
    return new Promise(resolve => {
        Promise.all([footNotes])
            .then(([ftnt]) => {
                if (ftnt.length) {
                    commit('setFootNotes', formatFootNotes(ftnt));
                }
                resolve();
            })
            .catch(error => {
                commit(
                    'ui/setError',
                    { type: 'table', payload: error.message },
                    { root: true }
                );
            });
    });
}

function getMedianRange(commit, questionId) {
    const medianRange = api.getMedianRange({ questionId });
    return new Promise(resolve => {
        Promise.all([medianRange])
            .then(([mr]) => {
                commit('setMedianRange', [formatMedianRangeByYear(mr)]);
                resolve();
            })
            .catch(error => {
                commit(
                    'ui/setError',
                    { type: 'table', payload: error.message },
                    { root: true }
                );
            });
    });
}


function getTableMedianRangeFootNoteData({ commit, questionId, yr, locationId, resolve }) {
    const tableData = getTableDataByYear(commit, questionId, yr, locationId);
    const footnotes = getFootnotes(commit, questionId);
    const MedianRange = getMedianRange(commit, questionId);
    // wait for the table data, footnotes and medianRange to come back
    // before resolving the promise
    // The UI needs to wait for this before updating the page
    commit('ui/setError', { type: 'table', payload: false }, { root: true });
    Promise.all([tableData, footnotes, MedianRange]).then(() => {
        resolve(yr); // send back the year incase it was not defined in the route query
    });
}
function getYearsByLocationAndQuestion(location, questionId) {
    if (location !== 'XX' && location !=='All') {
        const args = { questionId: questionId, locationId: location };

        return new Promise(resolve => {
            api
                .getYearsForLocationAndQuestion(args)
                .then(resp => {
                    return resolve(resp);
                })
        });
    } else {
        return new Promise(resolve => {
            return resolve([])
        });
    }
}
export const actions = {
    getTableData({ rootState, state, commit }, { questionId, year, locationId }) {
        return new Promise(resolve => {

            let StateLocalYears = [];

            getYearsByLocationAndQuestion(locationId, questionId)
                .then(yearData => {
                    StateLocalYears = formatYearData({ yearData });
                    if (StateLocalYears.length > 0) {
                        commit(
                            'ui/setStateLocalYears',
                            { StateLocalYears: StateLocalYears },
                            { root: true }
                        );
                    }

                    //if the input year isn't found in the location/question...grab the first one. need to return it in the resolve
                    year = StateLocalYears.length > 0 && StateLocalYears.indexOf(year) === -1 ? StateLocalYears.split(', ')[0] : year;

                    if (rootState.ui.QuestionCode !== questionId) {
                        // This is for a direct page load without navigating through the chart page.
                        // If the questionId is a new question ID go ahead an fetch everything
                        commit('resetAll');

                        api.getQuestion({ questionId, locationId }).then(([question]) => {

                            commit(
                                'ui/setQuestion',
                                { ...question, QuestionCode: questionId },
                                { root: true }
                            );
                            commit('setLocationId', [locationId]);
                            commit('setQuestionId', [questionId]);

                            const yr = year || question.QuestionYears.split(', ')[0];

                            getTableMedianRangeFootNoteData({ commit, questionId, yr, resolve, locationId: locationId });
                        });
                    } else if (questionId !== state.lastFetchedQuestionId) {
                        // if you are navigating from the Chart page, then the questionId might be the same
                        // but the footnotes and medianRange have not been updated.


                        const yr = year || rootState.ui.QuestionYears.split(', ')[0];
                        getTableMedianRangeFootNoteData({
                            commit,
                            questionId,
                            yr,
                            resolve,
                            locationId: locationId
                        });



                    } else if (!state.years[year] || locationId !== state.lastFetchedLocationId) {
                        // if it is not a new questionId and the year hasn't been requested yet
                        // get the new year data for the table
                        getTableDataByYear(commit, questionId, year, locationId).then(() => {
                            resolve(year);
                        });

                        commit('setLocationId', [locationId]);
                    } else {
                        // if it's not a new year or questionId you can just use
                        // the stored data, no nead for a new API call
                        resolve(year);
                    }
                })
        });
    },

};

export const getters = {
    flattenedNationalStateLocal: state => (year, topic, question, location, strat, allLocations) => {
        // get the State/National/Local stuff.

        const nationalItem = location == 'United States' ? state.years[year].National : null;
        const stateItem = state.years[year].State && state.years[year].State[location] ? state.years[year].State[location] : null;
        const localItem = state.years[year].Local && state.years[year].Local[location] ? state.years[year].Local[location] : null;

        let surveyType = '';
        if (nationalItem) {
            surveyType = 'National';
        } else if (stateItem) {
            surveyType = 'State';
        } else if (localItem) {
            surveyType = 'Local';
        };
        const region = nationalItem || stateItem || localItem;

        const flattened = [];
        for (let stratType in region) {
            for (let strat_1 in region[stratType]) {
                for (let strat_2 in region[stratType][strat_1]) {
                    const data = region[stratType][strat_1][strat_2];

                    if (!allLocations || allLocations && strat === stratType) {
                        flattened.push({
                            Year: year,
                            Survey: surveyType,
                            LocationDesc: location,
                            DataSource: 'YRBS',
                            Topic: topic,
                            Question: question.replace('*', ''),
                            Gender: strat_2,
                            StratificationCategory: stratType || 'Total',
                            Stratification: strat_1 === 'Total' ? 'Total' : strat_1,
                            DataValueUnit: '%',
                            DataValue: data.MainValue,
                            LowCI: data.LowCI,
                            HighCI: data.HighCI
                        });
                    }
                }
            }
        }
        return flattened;
    },

    flattenedNationalAllLocations: state => (year, topic, question, location, strat, allLocations) => {
        // get the State/National/Local stuff.

        const nationalItem = location == 'United States' ? state.years[year].National : null;
        const stateItem = state.years[year].State && state.years[year].State[location] ? state.years[year].State[location] : null;
        const localItem = state.years[year].Local && state.years[year].Local[location] ? state.years[year].Local[location] : null;
        const region = nationalItem || stateItem || localItem;

        const flattened = [];
        for (let stratType in region) {
            if (strat === 'Total') {
                for (let strat_1 in region[stratType]) {
                    for (let strat_2 in region[stratType][strat_1]) {
                        const data = region[stratType][strat_1][strat_2];

                        if (!allLocations || allLocations && strat === stratType) {
                            flattened.push({
                                Year: year,
                                Survey: 'National',
                                LocationDesc: location,
                                DataSource: 'YRBS',
                                Topic: topic,
                                Question: question.replace('*', ''),
                                StratificationCategory: stratType || 'Total',
                                Stratification: strat_2,
                                DataValueUnit: '%',
                                DataValue: data.MainValue,
                                LowCI: data.LowCI,
                                HighCI: data.HighCI
                            });
                        }
                    }
                }
            }
            else {

                for (let strat_2 in region[stratType]) {
                    const data = region[stratType][strat_2]['Total'];

                    if (!allLocations || allLocations && strat === stratType) {
                        flattened.push({
                            Year: year,
                            Survey: 'National',
                            LocationDesc: location,
                            DataSource: 'YRBS',
                            Topic: topic,
                            Question: question.replace('*', ''),
                            StratificationCategory: stratType || 'Total',
                            Stratification: strat_2,
                            DataValueUnit: '%',
                            DataValue: data.MainValue,
                            LowCI: data.LowCI,
                            HighCI: data.HighCI
                        });
                    }

                }
            }
        }
        return flattened;
    },
    flattenedStateLocalAllLocations: state => (year, topic, question, strat, allLocations) => {
        const flattened = [];
        function loop(survey) {

            const regions = state.years[year][survey];

            if (!regions) return;

            const medianRange = state.medianRange[year][survey];
            for (let region in regions) {
                for (let strat_1 in regions[region]) {
                    if (strat === 'Total') {
                        for (let strat_2 in regions[region][strat_1]['Total']) {
                            try {
                                const data = regions[region][strat_1]['Total'][strat_2];
                                const { MedianValue, MinValue, MaxValue } = medianRange[strat === 'Total' ? 'Sex' : strat][strat_2];

                                if (!allLocations || allLocations && strat_1 == strat) {
                                    flattened.push({
                                        Year: year,
                                        Survey: survey,
                                        LocationDesc: region,
                                        DataSource: 'YRBS',
                                        Topic: topic,
                                        Question: question.replace('*', ''),
                                        StratificationCategory: strat_1,
                                        Stratification: strat_2,
                                        DataValueUnit: '%',
                                        DataValue: data.MainValue,
                                        LowCI: data.LowCI,
                                        HighCI: data.HighCI,
                                        Median: MedianValue,
                                        Minimum: MinValue,
                                        Maximum: MaxValue

                                    });
                                }

                            }
                            catch (ex) {
                                console.log('strat_1:' + strat_1 + ' strat_2:' + strat_2)
                            }
                        }
                    }
                    else {
                        for (let strat_2 in regions[region][strat_1]) {
                            try {
                                const data = regions[region][strat_1][strat_2]['Total'];

                                const { MedianValue, MinValue, MaxValue } = medianRange[strat === 'Total' ? 'Sex' : strat][strat_2];


                                if (!allLocations || allLocations && strat_1 == strat) {
                                    flattened.push({
                                        Year: year,
                                        Survey: survey,
                                        LocationDesc: region,
                                        DataSource: 'YRBS',
                                        Topic: topic,
                                        Question: question.replace('*', ''),
                                        StratificationCategory: strat_1,
                                        Stratification: strat_2,
                                        DataValueUnit: '%',
                                        DataValue: data.MainValue,
                                        LowCI: data.LowCI,
                                        HighCI: data.HighCI,
                                        Median: MedianValue,
                                        Minimum: MinValue,
                                        Maximum: MaxValue,

                                    });
                                }


                            }
                            catch (ex) {
                                console.log('strat_1:' + strat_1 + ' strat_2:' + strat_2)
                            }
                        }
                    }
                }
            }

        }


        loop('State');
        loop('Local');
        return flattened;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
