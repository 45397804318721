<template>
    <div class="container-fluid site-title">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div aria-label="Title" class="display-6 text-white fw-500 pt-1 pb-1">
                        <span class="yrbs-title">
                            <i class="fas fa-home "></i>
                            {{ title }}
                        </span>
                        <div class="help d-print-none"
                             @click="helpLink"
                             @keyup.enter="helpLink"
                             tabindex="0"
                             role="link">
                            <i class="fas fa-question-circle"></i> Help Using YRBS Explorer
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { selectHelp as helpFromHome } from './Home/analyticsHelpers';
    import { selectHelp as helpFromGraph } from './Charts/analyticsHelpers';
    import { selectHelp as helpFromTable } from './Tables/analyticsHelpers';
    import { env } from '../utils/helpers';

    export default {
        props: ['title'],
        methods: {
            helpLink() {
                const { $router } = this;
                this.$store.dispatch('ui/setTopicCode', null);
                this.$store.dispatch('ui/setSearchTerm', null);
                switch ($router.currentRoute.value.path) {
                    case '/graphs': {
                        helpFromGraph();
                        break;
                    }
                    case '/tables': {
                        helpFromTable();
                        break;
                    }
                    default: {
                        helpFromHome();
                    }
                }
                const url = env('cdc') + '/healthyyouth/data/yrbs/explorer/help.htm';
                window.open(url, '_blank');
            }
        }
    };
</script>

<style lang="scss">
    .homeIcon {
        font-size: 37px;
        text-decoration: none !important;
    }

    .help {
        display: absolute;
        float: right;
        font-size: medium;
        padding-top: 5px;

        &:hover {
            text-decoration: underline !important;
        }
    }

    @media print {
        .yrbs-title {
            font-size:16px;
        }
    }
</style>
