<template condense>
    <div class="d-print-none gray-rounded-box">
        <p v-if="!hideGraphAndYOLLink && type"
           class="link"
           @click="goToLink"
           @keyup.enter="goToLink"
           role="link"
           tabindex="0"
           :key="$route.query.year">
            <i aria-hidden="true" :class="'fas fa-' + icon"></i>{{ 'View ' + graphOrTable }}
        </p>
        <p v-if="!hideGraphAndYOLLink"
           class="link"
           @click="goToYOL"
           @keyup.enter="goToYOL"
           role="link"
           tabindex="0">
            <i aria-hidden="true" class="fas fa-arrow-right"></i>View Table in Youth
            Online
        </p>
        <GetLink v-if="showShareLink" />
    </div>
</template>

<script>
    import GetLink from './GetLink.vue';
    import { createLinkToYOL } from '../../utils/helpers';
    import {
        selectViewTable,
        selectViewInYOL as YOLfromGraph
    } from '../Charts/analyticsHelpers';
    import {
        selectViewGraph,
        selectViewInYOL as YOLfromTable
    } from '../Tables/analyticsHelpers';

    export default {
        props: {
            type: String,
            queryParams: Object,
            hideGraphAndYOLLink: { type: Boolean, default: false },
            showShareLink: { type: Boolean, default: true }
        },
        components: { GetLink },
        computed: {
            icon() {
                return this.type === 'graph' ? 'chart-bar' : 'table';
            },
            graphOrTable() {
                return this.type.charAt(0).toUpperCase() + this.type.substring(1);
            },
            youthOnlineLink() {
                const { questionCode, year, location } = this.getQuery();
                const columnVariable = 'Sex'; // needs to be Sex by default because this is unknown
                const args = {
                    questionCode,
                    locationId: location === 'All' ? 'XX' : (location || 'XX'),
                    year: year || 'YY',
                    columnVariable,
                    nativeDirection: this.$store.state.ui.NativeDirection
                };
                return createLinkToYOL(args);
            }
        },
        methods: {
            getQuery() {
                return this.queryParams
                    ? { ...this.$route.query, ...this.queryParams }
                    : this.$route.query;
            },
            goToLink() {
                const { questionCode, topicCode, year, location } = this.getQuery();
                let pushTo = `/${this.type
                    }s?questionCode=${questionCode}&topicCode=${topicCode}&year=${year}`;
                const locationCode = location === 'All' ? 'XX' : (location || 'XX');
                if (locationCode) pushTo += `&location=${locationCode}`;
                if (this.type === 'graph')
                    selectViewGraph({ questionCode, year, locationCode });
                if (this.type === 'table')
                    selectViewTable({ questionCode, year, locationCode });
                this.$router.push(pushTo);
            },
            goToYOL() {
                const { questionCode, year, location } = this.getQuery();
                if (this.type === 'graph')
                    YOLfromTable({ questionCode, year, locationCode: location });
                if (this.type === 'table')
                    YOLfromGraph({ questionCode, year, locationCode: location });
                window.open(this.youthOnlineLink, '_blank');
            }
        }
    };
</script>

<style lang="scss"></style>
