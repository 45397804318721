export default {
    /* This list was created by querying the Youth Online database
     *  NCCD_SDM_NCHHSTP_YRBSS database to obtain the location metadata.
     *                                                         4/20/20
     * This list does not include:
     *                Minnesota, Oregon, Washington
     */
    byCode: {
        All: { All: 'All Locations' },
        National: {
            XX: 'United States'
        },
        States: {
            AL: 'Alabama',
            AK: 'Alaska',
            AZB: 'Arizona',
            AR: 'Arkansas',
            CA: 'California',
            CO: 'Colorado',
            CT: 'Connecticut',
            DE: 'Delaware',
            FL: 'Florida',
            GA: 'Georgia',
            HI: 'Hawaii',
            ID: 'Idaho',
            IL: 'Illinois',
            IN: 'Indiana',
            IA: 'Iowa',
            KS: 'Kansas',
            KY: 'Kentucky',
            LA: 'Louisiana',
            ME: 'Maine',
            MD: 'Maryland',
            MA: 'Massachusetts',
            MI: 'Michigan',
            MS: 'Mississippi',
            MO: 'Missouri',
            MT: 'Montana',
            NE: 'Nebraska',
            NV: 'Nevada',
            NH: 'New Hampshire',
            NJ: 'New Jersey',
            NM: 'New Mexico',
            NY: 'New York',
            NC: 'North Carolina',
            ND: 'North Dakota',
            OH: 'Ohio',
            OK: 'Oklahoma',
            PA: 'Pennsylvania',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VT: 'Vermont',
            VA: 'Virginia',
            WV: 'West Virginia',
            WI: 'Wisconsin',
            WY: 'Wyoming'
        },
        'Local School Districts': {
            AB: 'Albuquerque, NM',
            BA: 'Baltimore, MD',
            //NOTE: 5/3 the Borough of NY...was discussed 5/2/2023 to 5/3/2023
            //These locations are intentionally hidden because of medium data
            //Instead, data is shown for New York City, NY
            //NYG: 'Borough of Bronx, NY',
            //NYH: 'Borough of Brooklyn, NY',
            //NYI: 'Borough of Manhattan, ',
            //NYJ: 'Borough of Queens, NY',
            //NYK: 'Borough of Staten Island, NY',
            BO: 'Boston, MA',
            FT: 'Broward County, FL',
            CM: 'Charlotte-Mecklenburg County, NC',
            CH: 'Chicago, IL',
            CC: 'Clark County, NV',
            CE: 'Cleveland, OH',
            DA: 'Dallas, TX',
            DKC: 'DeKalb County, GA',
            DN: 'Denver, CO',
            DT: 'Detroit, MI',
            DCB: 'District of Columbia',
            DU: 'Duval County, FL',
            EA: 'Eaton Consortium, MI',
            FW: 'Fort Worth, TX',
            GS: 'Gaston County, NC',
            GE: 'Genesee Consortium, MI',
            HL: 'Hillsborough County, FL',
            HO: 'Houston, TX',
            LO: 'Los Angeles, CA',
            MEM: 'Memphis, TN',
            MM: 'Miami-Dade County, FL',
            ML: 'Milwaukee, WI',
            NS: 'Nashville, TN',
            NO: 'New Orleans, LA',
            NYC: 'New York City, NY',
            NW: 'Newark, NJ',
            OA: 'Oakland, CA',
            OL: 'Orange County, FL',
            PB: 'Palm Beach County, FL',
            PS: 'Pasco County, FL',
            PH: 'Philadelphia, PA',
            PO: 'Portland, OR',
            SB: 'San Bernardino, CA',
            SA: 'San Diego, CA',
            SF: 'San Francisco, CA',
            SE: 'Seattle, WA',
            ST: 'Shelby County, TN',
            SP: 'Spartanburg County, SC'
        }
    },
    byCodeFlat() {
        const bC = this.byCode;
        return {
            ...bC.National,
            ...bC.States,
            ...bC['Local School Districts']
        };
    },
    byName() {
        const flattened = this.byCodeFlat();
        const reversedObject = {};
        Object.keys(flattened).forEach(locAbbreviation => {
            const locName = flattened[locAbbreviation];
            reversedObject[locName] = locAbbreviation;
        });
        return reversedObject;
    }
};



           