import { select } from 'd3-selection';

export function createSVG({ $el, width, margin, height, description, title }) {
  const el = select($el)
    .append('svg')
    .attr('width', width)
    .attr('height', height);
  el.append('title').text(title);
  el.append('desc').text(description);
  return el
    .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
}

export function svgGTranslate(svg, translation) {
  const { x, y } = translation;
  return svg.append('g').attr('transform', `translate(${x},${y})`);
}

export function calcOrdinalRange(data, max, accumulate = true) {
  let previousValue = 0;
  const ordinalRange = data.map(dataArray => {
    const elements = dataArray.length;
    const { totalBars } = data;
    let value = max * (elements / totalBars);
      if (accumulate) value += previousValue
    previousValue = value;
    return value;
  });
  return ordinalRange;
}

export function getYearRange(dataArray) {
  const memo = {};
  dataArray.forEach(d => {
    memo[d.Year] = true;
  });
  return Object.keys(memo).sort();
}

export function getMinMaxYears(dataArray) {
  let min = 3000;
  let max = 0;
  for (let i = 0; i < dataArray.length; i++) {
    const year = parseInt(dataArray[i].Year, 10);
    if (year < min) min = year;
    if (year > max) max = year;
  }
  return [min, max];
}
export function getMinMaxValues(dataArray) {
    let min = 3000;
    let max = 0;
    for (let i = 0; i < dataArray.length; i++) {
        const MainValue = parseInt(dataArray[i].MainValue, 10);
        if (MainValue < min) min = MainValue;
        if (MainValue > max) max = MainValue;
    }
    return [min-5, max+ 5];
}
export function negativeAxisCheck(value) {
    if (value < 0) return 0;
    return value;
}
