let VUE_APP_APIBaseURL = '';

const HOST_ENV = getHostEnvironment();

function getHostEnvironment() {
    var hostName = window.location.hostname;
    if (hostName.includes('localhost')) return 'localhost';
    if (hostName.includes('qa-yrbs-explorer.apps.ecpaas-dev.cdc.gov')) return 'qa';
    if (hostName.includes('yrbs-explorer.apps.ecpaas-dev.cdc.gov')) return 'dev';
    if (hostName.includes('-int.cdc.gov')) return 'int';
    // default to prod
    return 'prod';
} // .getHostEnvironment


switch (HOST_ENV) {
    case 'localhost':
        VUE_APP_APIBaseURL = 'http://localhost:64382/api'//'https://yrbs-explorer.apps.ecpaas-dev.cdc.gov/api';//
        break;
    case 'dev':
        VUE_APP_APIBaseURL = 'https://yrbs-explorer.apps.ecpaas-dev.cdc.gov/api';        
        break;
    case 'qa':
        VUE_APP_APIBaseURL = 'https://qa-yrbs-explorer.apps.ecpaas-dev.cdc.gov/api';        
        break;
    case 'int':
        VUE_APP_APIBaseURL = 'https://yrbs-explorer.services-int.cdc.gov/api'; 
        break;
    case 'prod':
        VUE_APP_APIBaseURL = 'https://yrbs-explorer.services.cdc.gov/api'; 
        break;
    default:
}

module.exports = {
    VUE_APP_APIBaseURL: VUE_APP_APIBaseURL    
}
